import React from "react";
import {Box, Typography} from "@mui/material";
import {ArrowRightAlt, ArrowBack} from "@mui/icons-material";
import "../../assets/css/buttons/NextButton.css"

const NextButton = ({text = "Neste", onClick, animate = false, bottom = true, size = "inherit", reversed = false}) => {
    return (
        <Typography
            variant="h4"
            gutterBottom
            sx={{fontFamily: "Poppins", bottom: bottom ? "1em" : "50%", fontSize: size, left: reversed ? "1em" : ""}}
            className={`next-button ${animate ? "animate" : ""}`}
            onClick={onClick}
        >
            <Box sx={{display:'flex', alignItems:'center'}}>
                {reversed ? (
                    <>
                        <span className="arrow-icon"><ArrowBack/></span> {text}
                    </>
                ) : (
                    <>
                        {text} <span className="arrow-icon"><ArrowRightAlt/></span>
                    </>
                )}
            </Box>
        </Typography>
    );
};

export default NextButton;
