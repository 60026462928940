import React from 'react';
import {useNavigate} from 'react-router-dom';
import SuccessPageLayout from '../../components/paymentSuccess/SuccessPageLayout';
import ActionCard from '../../components/paymentSuccess/ActionCard';
import {Box, Typography} from '@mui/material';
import {AnimationLinks} from "../../animations";
import {CustomButton} from "../../components/customComponents/CustomButton";
import generateDifmCheckoutLink from "../../helperFunctions/difm/generateDifmCheckoutLink";
import ReplayIcon from '@mui/icons-material/Replay';

const DifmPaymentFailedPage = () => {
    const navigate = useNavigate();

    const navigateToTemplates = () => {
        navigate('/nettside/nettsidemaler');
    };

    const navigateToAi = () => {
        navigate('/nettside?openAiModal=true')
    }

    const cards = [
        {
            title: 'Velg en mal',
            description: 'Sett i gang med å lage din egen nettside - det er enkelt!',
            videoSrc: AnimationLinks().malerAnimation,
            onClick: navigateToTemplates,
        },
        {
            title: 'Bygg en nettside med AI',
            description: 'Trenger du noe raskt? La AI bygge et utkast av nettsiden din!',
            videoSrc: AnimationLinks().aiByggerAnimation,
            onClick: navigateToAi,
        },
    ].filter(Boolean);

    const title = `Lag nettside for meg bestilling feilet`;
    const difmCaptionText = (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '1em',
            padding: '0',
            alignItems: 'center',
        }}>
            <CustomButton variant="contained" size="large" colors="secondary" sx={{width: 'fit-content'}} endIcon={<ReplayIcon/>}
                          onClick={generateDifmCheckoutLink}>Prøv igjen</CustomButton>
            <Typography variant="body">
                Ombestemt deg og vil heller gjøre det selv? <br/>Bygg en nettside med en av våre 200+ nettsidemaler
                eller AI-nettsidebyggeren under.
            </Typography>
        </Box>
    );

    return (
        <SuccessPageLayout
            title={title}
            subscriptionTypeText={""}
            difmCaptionText={difmCaptionText}
        >
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'flex-start',
                    flexWrap: 'no-wrap',
                    gap: {xs: '1em', sm: '3em', md: '7em', lg: '8em'},
                }}
            >
                {cards.map((card, index) => (
                    <ActionCard key={index} {...card} />
                ))}
            </Box>
        </SuccessPageLayout>
    );
};

export default DifmPaymentFailedPage;
