import { fetchSubscriptionStatus } from "../stripe/fetchSubscriptionStatus";
import React, { createContext, useContext, useEffect, useState, useCallback } from 'react';

const SubscriptionContext = createContext();

export const SubscriptionProvider = ({ children }) => {
    const [subscriptionData, setSubscriptionData] = useState(null);
    const [loading, setLoading] = useState(true);

    const fetchSubscription = useCallback(async () => {
        setLoading(true);
        try {
            const data = await fetchSubscriptionStatus();
            setSubscriptionData(data);
        } catch (error) {
            console.error('Error fetching subscription data:', error);
        } finally {
            setLoading(false);
        }
    }, []);

    useEffect(() => {
        fetchSubscription();
    }, [fetchSubscription]);

    return (
        <SubscriptionContext.Provider value={{ subscriptionData, loading, refreshSubscription: fetchSubscription }}>
            {children}
        </SubscriptionContext.Provider>
    );
};

export const useSubscription = () => useContext(SubscriptionContext);