// axiosInstance.js
import axios from 'axios';
import refreshGoogleCredentials from "../google/refreshGoogleCredentials";

// Use the backend service URL as the base URL for production, and localhost for development
const BASE_URL = process.env.REACT_APP_BACKEND_URL || 'http://localhost:8000/';

const axiosInstance = axios.create({
    baseURL: BASE_URL, // Use the base URL here as the base domain for all requests
    withCredentials: true, // Ensure credentials/cookies are sent with requests
});

axiosInstance.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem('jwtToken');
        if (token) {
            config.headers['Authorization'] = 'Bearer ' + token;
        }
        config.headers['Content-Type'] = 'application/json';
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

const isExpiredGoogleTokenError = (errorResponse) => {
// Function to check if any part of the error response indicates an expired Google access token
    const googleTokenExpiredMessages = [
        "Expired Google access token",
        "Request had invalid authentication credentials",
        "invalid_grant", // Common error code returned by Google APIs for expired tokens
        "401 Request had invalid authentication credentials. Expected OAuth 2 access token, login cookie or other valid authentication credential.",
    ];
    const errorText = JSON.stringify(errorResponse); // Convert the entire error response to a string for searching

    // Check if any known error message indicating an expired token is present in the error response
    return googleTokenExpiredMessages.some(message => errorText.includes(message));
};

axiosInstance.interceptors.response.use(
    (response) => response,
    async (error) => {
        const originalRequest = error.config;

        if ((error.response.status === 401 || error.response.status === 403) && !originalRequest._retry) {
            originalRequest._retry = true;

            // Refresh token logic for JWT
            const jwtRefreshToken = localStorage.getItem('jwtRefreshToken');
            if (jwtRefreshToken && (error.response.data.error === "JWT token has expired." || error.response.error === "JWT token has expired.")) {
                try {
                    const {data} = await axiosInstance.post('/refresh-access-token/', {jwt_refresh_token: jwtRefreshToken});
                    localStorage.setItem('jwtToken', data.token);
                    originalRequest.headers['Authorization'] = 'Bearer ' + data.token;
                    return axiosInstance(originalRequest);
                } catch (refreshError) {
                    localStorage.clear(); // or your logout logic
                    window.location.href = '/login';
                    return Promise.reject(refreshError);
                }
            }

        }

        // Refresh token logic for Google
        // Check if the error is due to an expired Google access token
        if (isExpiredGoogleTokenError(error.response)) {
            try {
                const googleRefreshToken = localStorage.getItem('google_refresh_token');
                if (googleRefreshToken) {
                    const refreshResponse = await refreshGoogleCredentials(googleRefreshToken);
                    if (refreshResponse && refreshResponse.success) {
                        if (refreshResponse.jwtToken) {
                            localStorage.setItem('jwtToken', refreshResponse.jwtToken);
                            originalRequest.headers['Authorization'] = 'Bearer ' + refreshResponse.jwtToken;
                        }
                        // Retry the original request with the new tokens
                        return axiosInstance(originalRequest);
                    }
                }
            } catch (refreshError) {
                console.error('Error refreshing Google credentials:', refreshError);
                // Handle token refresh failure (e.g., clear storage, redirect to login)
                localStorage.clear();
                window.location.href = '/login';
                return Promise.reject(refreshError);
            }
        }

        // If the error does not match the expired token cases, reject the promise with the original error
        return Promise.reject(error);
    }
);

export default axiosInstance;
