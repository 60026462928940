import axiosInstance from '../authentication/axiosInstance';

export const fetchSubscriptionStatus = async () => {
    try {
        const response = await axiosInstance.get('/get-subscription-status/', {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`
            }
        });

        const data = response.data;

        const mappings = {
            subscription_status: data.subscription_status,
            subscription_type: data.subscription_type,
            hasHadTrial: data.has_had_trial,
            paidDifm: data.paid_difm,
            difm_completed: data.difm_completed,
        };

        Object.entries(mappings).forEach(([key, value]) => {
            localStorage.setItem(key, value);
        });

        return {
            status: data.subscription_status,
            subscription_type: data.subscription_type,
            trialDays: data.trial_days_remaining,
            isAdmin: data.isAdmin,
            websiteQuota: data.website_quota,
            paidDifm: data.paid_difm,
            difm_completed: data.difm_completed,
        };
    } catch (error) {
        console.error('Error fetching subscription status', error);
        return null;
    }
};
