import React, {useEffect, useState} from "react";
import SuccessPageLayout from "../../components/paymentSuccess/SuccessPageLayout";
import ActionCard from "../../components/paymentSuccess/ActionCard";
import {Box, Typography} from "@mui/material";
import checkCompleteOnboardingAndDifm from "../../helperFunctions/difm/removeDifmFlag";
import {fetchTypeformResults} from "../../helperFunctions/typeform/fetchTypeformResults";
import Loader from "../../components/customComponents/Loader";
import {AnimationLinks} from "../../animations";

const DifmPaymentSuccessPage = () => {
    const [typeformSummary, setTypeformSummary] = useState(null);
    const [loading, setLoading] = useState(true);
    const [showDetails, setShowDetails] = useState(false);
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768); // Mobile threshold
        };

        // listener for screen size changes
        handleResize();
        window.addEventListener("resize", handleResize);

        return () => window.removeEventListener("resize", handleResize);
    }, []);

    useEffect(() => {
        const fetchResults = async () => {
            setLoading(true);
            const result = await fetchTypeformResults();
            if (result) {
                const summary = summarizeTypeformResponse(result);
                setTypeformSummary(summary);
            }
            setLoading(false);
        };

        fetchResults();
        checkCompleteOnboardingAndDifm();
    }, []);

    const summarizeTypeformResponse = (response) => {
        const answers = response?.answers || [];
        const summary = {};

        // Extract key fields
        answers.forEach((answer) => {
            if (answer.field?.id === "G7WxlwPdiwqy") { // Business name id
                summary.businessName = answer.text;
            }
            if (answer.field?.id === "x4wTF1Ik33a2") { // location
                summary.location = answer.text;
            }
            if (answer.field?.id === "tduclz04nIWL") {
                summary.branch = answer.text;
            }
            if (answer.field?.id === "DQjxUPLLW7DZ") {
                summary.email = answer.email;
            }
            if (answer.field?.id === "RFS8opW2cHqV") {
                summary.phone_number = answer.phone_number;
            }
        });

        // Format the submitted_at timestamp
        if (response?.submitted_at) {
            const date = new Date(response.submitted_at);
            summary.submittedAt = date.toLocaleString("no-NO", {
                year: "numeric",
                month: "long",
                day: "numeric",
                hour: "2-digit",
                minute: "2-digit",
            });
        }

        return summary;
    };

    const renderSummary = () => {
        if (!typeformSummary) return null;

        return (
            <Box sx={{display: "flex", flexDirection: "column", textAlign: "center"}}>
                <Typography variant="body">
                    🏢 <b>Bedrift & Lokasjon</b>: {typeformSummary.businessName}, {typeformSummary.location}
                </Typography>
                <Typography variant="body">
                    💼 <b>Bransje</b>: {typeformSummary.branch}
                </Typography>
                <Typography variant="body">
                    📧 <b>E-post</b>: {typeformSummary.email}
                </Typography>
                <Typography variant="body">
                    📞 <b>Telefon</b>: {typeformSummary.phone_number}
                </Typography>
                <Typography variant="body">
                    📅 <b>Bestillingsdato</b>: {typeformSummary.submittedAt}
                </Typography>
            </Box>
        );
    };

    const cards = [
        {
            title: "Domeneshop",
            description: "Domeneshop.no er en kjent leverandør som tilbyr .no, .com og mange andre domener til så lite som 10kr per måned.",
            imageSrc: "https://domene.shop/svg/logo-no.svg",
            onClick: () => window.open("https://domene.shop/", "_blank"),
            manualWidth: isMobile ? "80%" : "25%",
            buttonText: "Kjøp domene i Domeneshop",
        },
        {
            title: "GoDaddy",
            description: "GoDaddy tilbyr et bredt utvalg av domener, inkludert .no og .shop, med pålitelig kundeservice og fokus på sikkerhet.",
            imageSrc: AnimationLinks().goDaddyLogo,
            onClick: () => window.open("https://no.godaddy.com/", "_blank"),
            manualWidth: isMobile ? "80%" : "25%",
            buttonText: "Kjøp domene i GoDaddy",
        },
    ].filter(Boolean);

    const title = `🎉 Vi har mottatt din bestilling og setter i gang nå!`;
    const difmCaptionText = (
        <Box sx={{display: "flex", flexDirection: "column", gap: "1em", padding: "0", textAlign: "center"}}>
            <Typography variant="body" sx={{lineHeight: isMobile ? "2em" : "initial"}}>
                🎯 Vi har mottatt ordren din for en tilpasset nettside, og teamet vårt har allerede startet prosessen.<br/>
                📨 Du vil motta en <b>e-post fra oss innen 48 timer</b> med detaljer om nettsiden din og videre steg.<br/>
                ❓ Har du spørsmål i mellomtiden? Ikke nøl med å kontakte oss – vi er her for å hjelpe.
            </Typography>
            <Typography variant="body">
                📞 +47 45 111 000 <br/>
                📧 post@groweb.no
            </Typography>

            {loading ? (
                <Loader alignItems={"center"} height={"fit-content"} />
            ) : (
                <>
                    <Typography
                        variant="h5"
                        sx={{textDecoration: "underline", cursor: "pointer"}}
                        onClick={() => setShowDetails(!showDetails)}
                    >
                        {showDetails ? "Skjul detaljer av din nettside bestilling" : "Vis detaljer av din nettside bestilling"}
                    </Typography>
                    {showDetails && typeformSummary && renderSummary()}
                </>
            )}
            <Typography variant="body">
                🌐 <b>Trenger du et domene til nettsiden din?</b> Gjerne sjekk ut en av våre anbefalte leverandører
            </Typography>
        </Box>
    );

    return (
        <SuccessPageLayout
            title={title}
            subscriptionTypeText=""
            difmCaptionText={difmCaptionText}
            isMobile={isMobile}
        >
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "flex-start",
                    flexWrap: isMobile ? "wrap" : "nowrap",
                    gap: isMobile? "1em" : {xs: "1em", sm: "3em", md: "4em", lg: "6em"},
                    paddingBottom: "1em",
                }}
            >
                {cards.map((card, index) => (
                    <ActionCard key={index} {...card} isMobile={isMobile}/>
                ))}
            </Box>
        </SuccessPageLayout>
    );
};

export default DifmPaymentSuccessPage;