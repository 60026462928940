import React, {memo, useMemo} from 'react';
import {IoLogOutSharp, IoSettings} from "react-icons/io5";
import {RiPagesFill} from "react-icons/ri";
import {FaBook} from "react-icons/fa";
import {MdAdminPanelSettings, MdDashboard} from "react-icons/md";
import {IoMdHelpCircle} from "react-icons/io";
import EngineeringIcon from '@mui/icons-material/Engineering';
import '../../assets/css/Navbar.css';
import {useLocation, useNavigate} from "react-router-dom";
import {googleLogout} from '@react-oauth/google';
import Typography from '@mui/material/Typography';
import {useSubscription} from "../../helperFunctions/subscription/SubscriptionProvider";

const Navbar = memo(() => {
    const navigate = useNavigate();
    const {subscriptionData, refreshSubscription} = useSubscription();
    const logoImageUrl = "https://growebmp4container-euczdjadazhtdkhf.z02.azurefd.net/growebmp4container/logo-final.webp"

    const logoutSuccess = () => {
        const selectedPropertyId = localStorage.getItem('selectedPropertyId');
        localStorage.clear()
        localStorage.setItem('selectedPropertyId', selectedPropertyId);
        navigate('/login/');
    };

    const handleLogoutClick = () => {
        // Logout from Google
        googleLogout();

        // Proceed with JWT and redirection logic
        logoutSuccess();
    };

    const goToHome = () => {
        navigate('/');
    }

    /*
    const goToTraffic = () => {
        navigate('/trafikk');
    }*/

    const goToGuides = () => {
        navigate('/guider');
    }

    const goToPage = (page) => {
        navigate(`/${page}`);
    }

    const firstName = localStorage.getItem('first_name');
    const lastName = localStorage.getItem('last_name');
    const isLongName = firstName && firstName.length > 13;

    // Function to create initials
    const getUserInitials = (firstName, lastName) => {
        return `${firstName.charAt(0)}${lastName.charAt(0)}`;
    };

    const isAdmin = localStorage.getItem('isAdmin') === 'true';

    const difmLink = subscriptionData?.difm_completed && subscriptionData?.paidDifm
        ? 'nettside'
        : subscriptionData?.paidDifm
            ? 'difm-suksess'
            : 'difm';

    // For navbar select highlighting
    const location = useLocation();
    const getNavItemStyle = (path) => {
        return location.pathname === path ? 'navbar-item-selected' : '';
    };

    const accountType = useMemo(() => {
        if (!subscriptionData) return 'Gratis-konto';
        if (subscriptionData.status === 'trialing') return 'Trial-konto';
        if (subscriptionData.status === 'active') return 'Abonnert';
        return 'Gratis-konto';
    }, [subscriptionData]);

    return (
        <div className="navbar-container">
            <div className="logo-section" onClick={goToHome} title="Hjem">
                <img src={logoImageUrl} alt="Groweb logo" className="logo-image"/>
            </div>

            <div className={`navbar-item ${getNavItemStyle('/')}`} onClick={goToHome} title="Dashboard">
                <MdDashboard className="navbar-icon"/>
                <Typography variant="body" className="navbar-text">Dashboard</Typography>
            </div>
            <div className={`navbar-item ${getNavItemStyle('/nettside')}`} onClick={() => goToPage('nettside')}
                 title="Nettside">
                <RiPagesFill className="navbar-icon"/>
                <Typography variant="body" className="navbar-text">Nettside</Typography>
            </div>
            <div
                className={`navbar-item`}
                onClick={() => goToPage(difmLink)}
                title="Lag nettsiden for meg"
            >
                <EngineeringIcon fontSize="medium" className="navbar-icon"/>
                <Typography variant="body" className="navbar-text" sx={{
                    textAlign: 'left',
                    overflow: 'hidden',
                    maxWidth: '120px',
                    display: 'inline-block',
                }}>{'Lag nettsiden for meg'}</Typography>
            </div>
            <div className={`navbar-item ${getNavItemStyle('/guider')}`} onClick={goToGuides} title="Guider">
                <FaBook className="navbar-icon"/>
                <Typography variant="body" className="navbar-text">Guider</Typography>
            </div>
            <div className={`navbar-item ${getNavItemStyle('/settings')}`}
                 onClick={() => goToPage('settings')}
                 title="Innstillinger">
                <IoSettings className="navbar-icon"/>
                <Typography variant="body" className="navbar-text">Innstillinger</Typography>
            </div>
            <div className={`navbar-item ${getNavItemStyle('/hjelp')}`} title="Hjelp"
                 onClick={() => goToPage('hjelp')}
                 title="Hjelp">
                <IoMdHelpCircle className="navbar-icon"/>
                <Typography variant="body" className="navbar-text">Hjelp</Typography>
            </div>
            {isAdmin && (
                <div className={`navbar-item ${getNavItemStyle('/admin')}`} onClick={() => goToPage('admin')}
                     title="Admin">
                    <MdAdminPanelSettings className="navbar-icon"/>
                    <Typography variant="body" className="navbar-text">Admin</Typography>
                </div>
            )}
            <div className="user-section">
                <div className="user-profile">
                    <div className="user-info">
                        <div className="initials-icon" data-initials={getUserInitials(firstName, lastName)}></div>
                        <div className="user-info-text">
                            <span className="firstName"
                                  style={{fontSize: isLongName ? 'small' : 'inherit'}}>{firstName}</span>
                            <span className="account-type">{accountType}</span>
                        </div>
                    </div>
                    <div className="logout"
                         style={{marginLeft: isLongName ? '15px' : '30px'}}
                         onClick={handleLogoutClick} title="Logg ut">
                        <IoLogOutSharp className="logout-icon"/>
                    </div>
                </div>
            </div>
        </div>
    );
});

export default Navbar;
