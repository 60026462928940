import React from 'react';
import {Box, Card, CardContent, Container, Typography} from '@mui/material';
import {AnimationLinks} from "../../animations";
import {useNavigate} from "react-router-dom";
import NextButton from "../buttons/NextButton";

const SuccessPageLayout = ({title, subscriptionTypeText, children, difmCaptionText, isMobile}) => {
    const navigate = useNavigate();
    const goToHome = () => {
        navigate('/');
    }

    return (
        <Container disableGutters maxWidth={false}
                   sx={{display: 'flex', flexDirection: 'row', height: '100vh', width: '100vw'}}>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '100%',
                }}
            >
                <Card sx={{
                    width: '100%',
                    height: '100%',
                    textAlign: 'center',
                    background: 'var(--beige-100)',
                    overflowY: 'auto'
                }}>
                    <div className="logoImg" onClick={goToHome} title="Hjem"
                         style={{margin: "0.5em 0em 0em 1em", display: 'flex'}}>
                        <img src={AnimationLinks().logoUrl} alt={"logo-image"} style={{height: "5em", width: "10em"}}/>
                    </div>
                    <CardContent sx={{padding: difmCaptionText ? "0em" : "inherit'"}}>
                        <Typography variant={isMobile ? "h5" : "h2"} component="div"
                                    sx={{fontFamily: 'Fraunces', padding: isMobile ? "16px" : "0em"}}
                                    gutterBottom>
                            {title}
                        </Typography>
                        <Typography variant="body" sx={{marginTop: '0.5em'}}>
                            {subscriptionTypeText}
                        </Typography>
                        <Typography
                            variant={difmCaptionText ? "body" : "caption"}
                            display="block"
                            sx={{
                                width: isMobile ? "90%" : '76%',
                                margin: isMobile ? '0em auto 1em' : '1.5em auto 1em',
                                fontWeight: difmCaptionText ? "inherit" : "bold"
                            }}
                        >
                            {difmCaptionText ? (
                                difmCaptionText
                            ) : (
                                <>
                                    Nå kan du starte jobben med å få flere kunder på nett! <br/>
                                    Det er lettere enn du tror.
                                </>
                            )}
                        </Typography>
                    </CardContent>
                    {/* Cards Section */}
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'flex-start',
                            flexWrap: 'wrap',
                            gap: {xs: '1em', sm: '1em', md: '2em'},
                            flexGrow: '1',
                            height: {xs: '10%', sm: '10%', md: '15%'},
                        }}
                    >
                        {children}
                    </Box>
                    {!isMobile && (
                        <NextButton text="Til Dashboard" sx={{
                            right: '1.5em',
                            bottom: '1.5em',
                            position: 'absolute',
                            textAlign: 'center',
                            display: 'flex',
                            alignItems: 'center',
                            cursor: 'pointer'
                        }} onClick={goToHome} animate={false}/>)
                    }
                </Card>
            </Box>
        </Container>
    );
};

export default SuccessPageLayout;
