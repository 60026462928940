import axiosInstance from "../authentication/axiosInstance";

export const fetchTypeformResults = async () => {
    try {
        const response = await axiosInstance.get("/get-typeform-results/", {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
        });

        if (response.data && response.data.result) {
            return response.data.result;
        }

        console.error("No result found in response:", response.data);
        return null;
    } catch (error) {
        console.error("Error fetching Typeform results:", error);
        return null;
    }
};