import {AnimationLinks} from "../../animations";
import React, {useEffect, useState} from "react";
import '../../assets/css/NewUserPage.css';
import NextButton from "../../components/buttons/NextButton";
import {useNavigate} from "react-router-dom";
import generateDifmCheckoutLink from "../../helperFunctions/difm/generateDifmCheckoutLink";

const DifmPage = () => {
    const navigate = useNavigate();
    const userEmail = localStorage.getItem('email');
    const firstName = localStorage.getItem('first_name');
    const [isMobile, setIsMobile] = useState(false);

    const submitted = async () => {
        await generateDifmCheckoutLink();
    };

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        handleResize();
        window.addEventListener("resize", handleResize);

        // Typeform message handler
        const handleMessage = (event) => {
            if (event.origin !== "https://rvusbx57zsx.typeform.com") return;
            const {type} = event.data;

            if (type === "form-submit") {
                submitted();
            }
        };

        window.addEventListener("message", handleMessage);

        return () => {
            window.removeEventListener("resize", handleResize);
            window.removeEventListener("message", handleMessage);
        };
    }, []);



    return (
        <div className="onboarding-container">
            <div className="onboarding-step">
                {!isMobile && (
                    <img
                        src={AnimationLinks().logoUrl}
                        alt="Hjem"
                        className="intro-logo"
                        onClick={() => navigate("/")}
                    />
                )}
                <iframe
                    src={`https://rvusbx57zsx.typeform.com/to/EGA9IRKJ?utm_source=platform#email=${encodeURIComponent(userEmail)}&name=${firstName}`}
                    className="onboarding-frame"
                    title="Lag det for meg"
                ></iframe>
                {!isMobile && (
                    <NextButton
                        text="Tilbake til Dashboard"
                        onClick={() => navigate("/")}
                        animate={false}
                        bottom={true}
                        reversed={true}
                    />
                )}
            </div>
        </div>
    );
};

export default DifmPage;
