import * as React from 'react';
import {useEffect, useState} from 'react';
import {CustomTextField} from '../components/customComponents/CustomTextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import handleLogin from "../components/login/handleLogin";
import {useNavigate, useLocation} from "react-router-dom";
import {useAuth} from "../helperFunctions/authentication/authContext";
import axiosInstance from "../helperFunctions/authentication/axiosInstance";
import {CircularProgress, CssBaseline, FormControlLabel, IconButton, InputAdornment} from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import {Visibility, VisibilityOff} from "@mui/icons-material";
import logoFinal from "../assets/img/logo-final.webp";
import {CustomCheckbox} from '../components/customComponents/CustomCheckbox';
import {color} from "../theme";
import {CustomButton} from "../components/customComponents/CustomButton";
import {load} from "recaptcha-v3";
import useMediaQuery from "@mui/material/useMediaQuery";
import GoogleAuthButton from "../components/login/googleLogin";
import videoSrcSmall from "../assets/mp4/slideshow-small.mp4";
import videoSrc from "../assets/mp4/slideshow.mp4";
import {useTheme} from "@mui/material/styles";

export default function SignUp() {
    const [errorMessage, setErrorMessage] = useState(null);
    const navigate = useNavigate();
    const location = useLocation();
    const {login} = useAuth();
    const [isLoading, setIsLoading] = useState(false); // Add loading state

    const [emailInput, setEmailInput] = useState('');
    const [emailInfo, setEmailInfo] = useState(null);
    const [emailValid, setEmailValid] = useState(false);

    const [firstNameError, setFirstNameError] = useState(false);
    const [lastNameError, setLastNameError] = useState(false);

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');

    const [firstNameValid, setFirstNameValid] = useState(false);
    const [lastNameValid, setLastNameValid] = useState(false);

    const [agreedToTerms, setAgreedToTerms] = useState(false); // For checkbox state

    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [passwordError, setPasswordError] = useState('');

    const [confirmPasswordError, setConfirmPasswordError] = useState('');

    const [captchaToken, setCaptchaToken] = useState(null);

    const [difm, setDifm] = useState(false);

    // Password visibility
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    // Responsive design for smaller screens
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));


    const checkEmailFormat = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const checkNameFormat = (name) => {
        // Allows letters from any language, hyphens, and spaces. Disallows numbers and special characters except for hyphens.
        const nameRegex = /^[a-zA-ZæøåÆØÅ\-\s]+$/;
        return nameRegex.test(name);
    };

    const validatePassword = (password) => {
        const errors = [];
        if (password.length < 8) {
            errors.push("Passordet må inneholde minst 8 tegn.");
        }
        if (!/[A-Z]/.test(password)) {
            errors.push("Passordet må inneholde minst én stor bokstav.");
        }
        if (!/[a-z]/.test(password)) {
            errors.push("Passordet må inneholde minst én liten bokstav.");
        }
        if (!/[0-9]/.test(password)) {
            errors.push("Passordet må inneholde minst ett tall.");
        }
        if (!/[!@#$%^&*()_+\-=[\]{};:'",.<>/?|\\~`]/.test(password)) {
            errors.push("Passordet må inneholde minst ett spesialtegn (f.eks. !, @, #).");
        }
        return errors;
    };

    const executeCaptcha = async () => {
        const recaptcha = await load("6LeH5PEpAAAAACxTT8bbXWh0zZ6hXhEoDcEwpI5L");
        const token = await recaptcha.execute('signup');
        setCaptchaToken(token)
        return token;
    };

    const handlePasswordChange = (e) => {
        const {value} = e.target;
        setPassword(value);
        const errors = validatePassword(value);
        setPasswordError(errors.join(' '));
    };

    useEffect(() => {
        if (emailInput) {
            const isValidFormat = checkEmailFormat(emailInput);
            if (isValidFormat) {
                const checkEmail = async () => {
                    try {
                        const response = await axiosInstance.post('/check-email/', {email: emailInput});
                        // Assuming the backend responds with { email_exists: true/false }
                        const emailExists = response.data.email_exists;
                        setEmailInfo({email_exists: emailExists});
                        setEmailValid(!emailExists);
                    } catch (error) {
                        console.error('Error checking email:', error);
                        setEmailInfo({email_exists: true}); // Assume true on error to prevent submission
                    }
                };
                checkEmail();
            } else {
                setEmailValid(false);
            }
        }
    }, [emailInput]);

    const signupDisabled = React.useMemo(() => {
        const emailIsValid = emailValid && checkEmailFormat(emailInput) && !emailInfo?.email_exists;
        const namesAreValid = firstNameValid && lastNameValid && !firstNameError && !lastNameError;
        const passwordIsValid = password.length > 0 && password === confirmPassword && !passwordError && !confirmPasswordError;
        const termsAccepted = agreedToTerms;
        return !emailIsValid || !namesAreValid || !passwordIsValid || !termsAccepted;
    }, [emailValid, emailInput, emailInfo, firstNameValid, lastNameValid, firstNameError, lastNameError, password, confirmPassword, passwordError, confirmPasswordError, agreedToTerms]);

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const difmValue = params.get("difm") === "true";
        setDifm(difmValue);
    }, [location.search]);

    const handleSubmit = async (event) => {
        event.preventDefault();
        const data = new FormData(event.target);
        const email = data.get('email');
        const password = data.get('password');
        const confirmPassword = data.get('confirm-password');
        const firstName = data.get('firstName');
        const lastName = data.get('lastName');

        const captchaToken = await executeCaptcha();

        if (!captchaToken) {
            setErrorMessage("reCAPTCHA feilet. Vennligst prøv igjen.");
            return;
        }

        if (!email) {
            setErrorMessage("E-postfeltet er tomt.");
            return;
        }

        if (!checkEmailFormat(email)) {
            setErrorMessage("E-posten er ikke gyldig.");
            return;
        }

        if (!password) {
            setErrorMessage("Passordfeltet er tomt.");
            return;
        }

        if (password !== confirmPassword) {
            setPasswordError("Passordene er ikke like.");
            setConfirmPasswordError("Passordene er ikke like.");
            return;
        }

        if (!firstName || !checkNameFormat(firstName)) {
            setFirstNameError(true);
            return;
        } else {
            setFirstNameError(false);
        }

        if (!lastName || !checkNameFormat(lastName)) {
            setLastNameError(true);
            return;
        } else {
            setLastNameError(false);
        }

        try {
            setIsLoading(true); // Set loading to true before starting login process
            await axiosInstance.post("/register/", {
                email, password, confirmPassword, firstName, lastName, 'g-recaptcha-response': captchaToken
            });
            await handleLogin({email: email, password: password, difm: difm}, navigate, login, setErrorMessage);
        } catch (error) {
            if (error.response) {
                setErrorMessage(error.response.data.error);
            } else {
                setErrorMessage("En ukjent feil har skjedd. Prøv igjen senere.");
            }
        } finally {
            setIsLoading(false);
        }
    };

    const video = isSmallScreen ? videoSrcSmall : videoSrc;

    return (
        <Box sx={{
            height: '100vh',
            width: '100vw',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            padding: 0,
            overflowY: 'auto',
            overflowX: 'auto'
        }}>
            <CssBaseline/>
            <Container component="main" maxWidth="false" disableGutters
                       sx={{height: isSmallScreen ? '100%' : 'auto', padding: 0}}>
                <Box sx={{
                    display: "flex",
                    flexDirection: isSmallScreen ? "column" : "row",
                    bgcolor: color.beige.beige100,
                    width: "100%",
                    boxShadow: `${color.black.black30} 0px 4px 8px`,
                    height: "fit-content",
                    overflow: 'hidden',
                }}>
                    {!isSmallScreen && (
                        <Box sx={{
                            width: '100%',
                            height: "auto",
                            overflow: 'hidden' // Ensure the video fits nicely within the box
                        }}>
                            <video autoPlay loop muted style={{width: '100%', height: '100%', objectFit: 'cover'}}>
                                <source src={video} type="video/mp4"/>
                                Your browser does not support the video tag.
                            </video>
                        </Box>
                    )}

                    <Box sx={{
                        width: '100%',
                        margin: 0,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}>
                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            width: '100%',
                            mb: 2,
                        }}>
                            <Box sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                width: isSmallScreen ? '0' : '100%',
                                height: isSmallScreen ? '0' : 'auto',
                                mb: isSmallScreen ? '0' : 2,
                                visibility: isSmallScreen ? 'hidden' : 'visible',
                                mt: isSmallScreen ? '0' : '64px',
                                ml: isSmallScreen ? '0' : '4em',
                            }}>
                                <a href="https://groweb.no" target="_blank" rel="noopener noreferrer"
                                   aria-label="Gå til Growebs hjemmeside">
                                    <img src={logoFinal} alt="Groweb logo2" style={{height: "80px"}}/>
                                </a>
                            </Box>
                            <Typography variant="h3" sx={{fontFamily: 'Fraunces', textAlign: 'center'}}>
                                Start 30 dagers gratis prøvetid
                            </Typography>
                        </Box>
                        <Box sx={{display: 'flex', alignItems: 'center', gap: 1, margin: '0 0 2em 0'}}>
                            <Typography variant="body" sx={{
                                fontFamily: 'Fraunces',
                                textAlign: 'center',
                                color: 'rgba(0, 0, 0, 0.6)',
                                fontWeight: 'bold',
                            }}>Har du allerede en konto? <a href="/" style={{textDecoration: 'none'}}>Logg
                                inn</a></Typography>
                        </Box>
                        <GoogleAuthButton isLoading={isLoading}
                                          setIsLoading={setIsLoading} difm={difm}/>
                        <Box sx={{display: 'flex', alignItems: 'center', gap: 4, margin: '2em 0 2em 0'}}>
                            <div style={{
                                flex: 1,
                                height: '1px',
                                width: '12vw',
                                backgroundColor: '#605BFFCC'
                            }}></div>
                            <Typography variant="body"
                                        sx={{color: color.green.baseGreen100, fontWeight: '500'}}> eller </Typography>
                            <div style={{
                                flex: 1,
                                height: '1px',
                                width: '12vw',
                                backgroundColor: '#605BFFCC'
                            }}></div>
                        </Box>

                        <Box component="form" noValidate onSubmit={handleSubmit}
                             sx={{mt: isSmallScreen ? 0 : 3, p: isSmallScreen ? '8px' : '0'}}>
                            <Grid container spacing={2} justifyContent="center" alignItems="center">
                                <Grid item xs={12}>
                                    <Box display="flex" flexDirection="column" alignItems="center" sx={{mb: 1}}>
                                        <Box width="100%" maxWidth="480px">
                                            <CustomTextField
                                                autoComplete="given-name"
                                                name="firstName"
                                                required
                                                fullWidth
                                                id="firstName"
                                                autoFocus
                                                error={firstNameError}
                                                helperText={firstNameError ? "Ugyldig fornavn. Tillatt: bokstaver, bindestreker og mellomrom." : ""}
                                                label="Fornavn"
                                                value={firstName}
                                                sx={{bgcolor: '#fff'}}
                                                onChange={e => {
                                                    const value = e.target.value;
                                                    setFirstName(value);
                                                    const isValid = checkNameFormat(value) && value.trim() !== '';
                                                    setFirstNameValid(isValid);
                                                    setFirstNameError(!isValid);
                                                }}
                                                InputProps={{
                                                    endAdornment: !firstNameError && firstName ? (
                                                        <InputAdornment position="end">
                                                            <CheckIcon style={{color: color.green.baseGreen100}}/>
                                                        </InputAdornment>
                                                    ) : null,
                                                }}
                                                FormHelperTextProps={{
                                                    sx: {backgroundColor: color.beige.beige100, margin: 0},
                                                }}
                                            />
                                        </Box>
                                    </Box>
                                </Grid>
                                <Grid item xs={12}>
                                    <Box display="flex" flexDirection="column" alignItems="center" sx={{mb: 1}}>
                                        <Box width="100%" maxWidth="480px">
                                            <CustomTextField
                                                required
                                                fullWidth
                                                id="lastName"
                                                name="lastName"
                                                autoComplete="family-name"
                                                error={lastNameError}
                                                helperText={lastNameError ? "Ugyldig etternavn. Tillatt: bokstaver, bindestreker og mellomrom." : ""}
                                                label="Etternavn"
                                                value={lastName}
                                                sx={{bgcolor: '#fff'}}
                                                onChange={e => {
                                                    const value = e.target.value;
                                                    setLastName(value);
                                                    const isValid = checkNameFormat(value) && value.trim() !== '';
                                                    setLastNameValid(isValid);
                                                    setLastNameError(!isValid);
                                                }}
                                                InputProps={{
                                                    endAdornment: !lastNameError && lastName ? (
                                                        <InputAdornment position="end">
                                                            <CheckIcon style={{color: color.green.baseGreen100}}/>
                                                        </InputAdornment>
                                                    ) : null,
                                                }}
                                                FormHelperTextProps={{
                                                    sx: {backgroundColor: color.beige.beige100, margin: 0},
                                                }}
                                            />
                                        </Box>
                                    </Box>
                                </Grid>
                                <Grid item xs={12}>
                                    <Box display="flex" flexDirection="column" alignItems="center" sx={{mb: 1}}>
                                        <Box width="100%" maxWidth="480px">
                                            <CustomTextField
                                                required
                                                fullWidth
                                                id="email"
                                                name="email"
                                                autoComplete="email"
                                                value={emailInput}
                                                onChange={(e) => setEmailInput(e.target.value)}
                                                error={emailInfo?.email_exists}
                                                label="E-post"
                                                sx={{bgcolor: '#fff'}}
                                                helperText={emailInfo?.email_exists ? "E-posten er allerede i bruk." : ""}
                                                InputProps={{
                                                    endAdornment: emailValid && !emailInfo?.email_exists && (
                                                        <InputAdornment position="end">
                                                            <CheckIcon style={{color: color.green.baseGreen100}}/>
                                                        </InputAdornment>
                                                    ),
                                                }}
                                                FormHelperTextProps={{
                                                    sx: {backgroundColor: color.beige.beige100, margin: 0},
                                                }}
                                            />
                                        </Box>
                                    </Box>
                                </Grid>
                                <Grid item xs={12}>
                                    <Box display="flex" flexDirection="column" alignItems="center" sx={{mb: 1}}>
                                        <Box width="100%" maxWidth="480px">
                                            <CustomTextField
                                                required
                                                fullWidth
                                                name="password"
                                                type={showPassword ? "text" : "password"}
                                                id="password"
                                                autoComplete="new-password"
                                                label="Passord"
                                                value={password}
                                                onChange={handlePasswordChange}
                                                error={!!passwordError}
                                                helperText={passwordError}
                                                sx={{bgcolor: '#fff'}}
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            {password && !passwordError ? <CheckIcon
                                                                style={{color: 'green', marginRight: '15px'}}/> : null}
                                                            <IconButton
                                                                aria-label="password"
                                                                onClick={() => setShowPassword(!showPassword)}
                                                                edge="end"
                                                            >
                                                                {showPassword ? <VisibilityOff/> : <Visibility/>}
                                                            </IconButton>
                                                        </InputAdornment>
                                                    ),
                                                }}
                                                FormHelperTextProps={{
                                                    sx: {backgroundColor: color.beige.beige100, margin: 0},
                                                }}
                                            />
                                        </Box>
                                    </Box>
                                </Grid>
                                <Grid item xs={12}>
                                    <Box display="flex" flexDirection="column" alignItems="center" sx={{mb: 1}}>
                                        <Box width="100%" maxWidth="480px">
                                            <CustomTextField
                                                placeholder="*******"
                                                required
                                                fullWidth
                                                name="confirm-password"
                                                type={showPassword ? "text" : "password"}
                                                id="confirm-password"
                                                autoComplete="new-password"
                                                label="Bekreft passord"
                                                value={confirmPassword}
                                                sx={{bgcolor: '#fff'}}
                                                onChange={(e) => {
                                                    setConfirmPassword(e.target.value);
                                                    if (password !== e.target.value) {
                                                        setConfirmPasswordError("Passordene er ikke like.");
                                                    } else {
                                                        setConfirmPasswordError('');
                                                    }
                                                }}
                                                error={confirmPasswordError !== ''}
                                                helperText={confirmPasswordError}
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            {confirmPassword && !confirmPasswordError && password === confirmPassword ?
                                                                <CheckIcon
                                                                    style={{
                                                                        color: color.green.baseGreen100,
                                                                        marginRight: '15px'
                                                                    }}/> : null}
                                                            <IconButton
                                                                aria-label="confirm-password"
                                                                onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                                                                edge="end"
                                                            >
                                                                {showConfirmPassword ? <VisibilityOff/> : <Visibility/>}
                                                            </IconButton>
                                                        </InputAdornment>
                                                    ),
                                                }}
                                                FormHelperTextProps={{
                                                    sx: {backgroundColor: color.beige.beige100, margin: 0},
                                                }}
                                            />
                                        </Box>
                                    </Box>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                {errorMessage && (
                                    <Typography variant="body" style={{color: 'red', marginTop: '1rem'}}>
                                        {errorMessage}
                                    </Typography>
                                )}
                            </Grid>
                            <Grid item xs={12} sx={{mt: 2, mb: 2, display: 'flex', justifyContent: 'center'}}>
                                <FormControlLabel
                                    control={
                                        <CustomCheckbox
                                            name="agreedToTerms"
                                            color="primary"
                                            checked={agreedToTerms} // Ensure the checkbox visually reflects the state
                                            onChange={(e) => setAgreedToTerms(e.target.checked)} // Update state on change
                                        />
                                    }
                                    label={
                                        <Typography variant="body" sx={{width: '70%', textWrap: 'pretty'}}>
                                            Ved bruk av nettsiden aksepterer du våre{" "}
                                            <a href="/personvern" target="_blank" rel="noopener noreferrer">
                                                personvernsvilkår
                                            </a>{" "}
                                            og{" "}
                                            <a href="/vilkår" target="_blank" rel="noopener noreferrer">
                                                bruksvilkår
                                            </a>.
                                        </Typography>
                                    }
                                    sx={{display: 'inline-flex', justifyContent: 'center', mr: 0, ml: 0}}
                                />
                            </Grid>
                            <Box sx={{padding: '0 16px'}}>
                                <CustomButton
                                    type="submit"
                                    colors="sixth"
                                    variant={signupDisabled ? "outlined" : "contained"}
                                    sx={{
                                        mt: 1,
                                        mb: 1,
                                        width: isSmallScreen ? "60%" : "40%",
                                        padding: '0.5em',
                                        textTransform: 'capitalize',
                                    }}
                                    disabled={signupDisabled}
                                    onClick={executeCaptcha} // Execute reCAPTCHA before submit
                                >
                                    {isLoading ?
                                        <CircularProgress size={30} style={{color: '#FFFFFF'}}/> : 'Opprett konto'}
                                </CustomButton>
                            </Box>
                            {/* reCAPTCHA v3 */}
                            <Box sx={{display: 'flex', justifyContent: 'center', marginBottom: '8px'}}>
                                <div id="recaptcha-container"/>
                            </Box>
                            <Box>
                                <Typography variant="caption" sx={{color: 'rgba(0, 0, 0, 0.6)'}}>
                                    Denne siden er beskyttet med reCAPTCHA og Google <a
                                    href="https://policies.google.com/privacy" target="_blank"
                                    rel="noopener noreferrer"
                                    style={{textDecoration: 'none', fontWeight: 'bold', color: 'rgba(0, 0, 0, 0.6)'}}>
                                    Personvern</a> og <a
                                    href="https://policies.google.com/terms" target="_blank"
                                    rel="noopener noreferrer"
                                    style={{
                                        textDecoration: 'none',
                                        fontWeight: 'bold',
                                        color: 'rgba(0, 0, 0, 0.6)',
                                    }}>Bruksvilkår</a> gjelder.
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Container>
        </Box>
    );
}
