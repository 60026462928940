import React from 'react';
import {Box, CircularProgress} from '@mui/material';

const Loader = ({size, alignItems, height}) => {
    return (
        <Box
            display="flex"
            justifyContent="center"
            alignItems={alignItems ? alignItems : "center"}
            height={height ? height : "100vh"}
        >
            <CircularProgress size={size}/>
        </Box>
    );
};

export default Loader;
