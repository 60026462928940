import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import axiosInstance from "../helperFunctions/authentication/axiosInstance";
import '../assets/css/NewUserPage.css';
import {AnimationLinks} from "../animations";
import NextButton from "../components/buttons/NextButton";

const NewUserPage = () => {
    const [currentStep, setCurrentStep] = useState(1);
    const navigate = useNavigate();
    const logoUrl = AnimationLinks().logoUrl;

    const nextStep = () => setCurrentStep((prev) => prev + 1);
    const seenDemo = async () => {
        try {
            await axiosInstance.post('/complete-onboarding/');
            localStorage.setItem('isNew', 'false');
        } catch (error) {
            console.error('Failed to complete onboarding:', error);
        }
        navigate('/nybruker-valg');
    }

    // Listen for Typeform submission events
    useEffect(() => {
        const handleMessage = (event) => {
            if (event.origin !== "https://rvusbx57zsx.typeform.com") return;
            const {type} = event.data;

            if (type === "form-submit") {
                nextStep();
            }
        };

        window.addEventListener("message", handleMessage);

        return () => {
            window.removeEventListener("message", handleMessage);
        };
    }, []);

    return (
        <div className="onboarding-container">
            {currentStep === 1 && (
                <div className="onboarding-step">
                    <img src={logoUrl} alt="Groweb intro logo" className="intro-logo"/>
                    <iframe
                        src="https://rvusbx57zsx.typeform.com/to/TVAqhXz4"
                        className="onboarding-frame"
                        title="Typeform Survey"
                    ></iframe>
                </div>
            )}

            {currentStep === 2 && (
                <div className="onboarding-step">
                    <iframe
                        src="https://app.sharefable.com/live/demo/kom-i-gang-med-groweb-j25qvlo5gp5ut6x5"
                        className="onboarding-frame"
                        title="Fable Demo"
                    ></iframe>
                    <NextButton text="Hopp over" onClick={seenDemo} animate={false} size={"1em"} bottom={false}/>
                </div>
            )}

        </div>
    );
};

export default NewUserPage;
