import React, {useState} from 'react';
import {useGoogleLogin} from '@react-oauth/google';
import handleLogin from "./handleLogin";
import {useNavigate} from "react-router-dom";
import {useAuth} from "../../helperFunctions/authentication/authContext";
import Typography from "@mui/material/Typography";
import {CircularProgress} from '@mui/material';
import {CustomButton} from '../customComponents/CustomButton';
import useMediaQuery from "@mui/material/useMediaQuery";
import theme from "../../theme";

const GoogleAuthButton = ({buttonTextOption, setIsLoading, isLoading, difm}) => {
    const [error, setError] = useState(null);
    const navigate = useNavigate();
    const {login} = useAuth();
    const isLargeScreen = useMediaQuery(theme.breakpoints.up('lg'));
    const isMediumScreen = useMediaQuery(theme.breakpoints.up('md'));

    const initiateGoogleLogin = useGoogleLogin({
        flow: 'auth-code',
        scope: 'openid profile email https://www.googleapis.com/auth/analytics.readonly',
        onSuccess: async (codeResponse) => {
            try {
                setIsLoading(true); // Set loading to true before starting login process
                if (codeResponse && codeResponse.code) {
                    await handleLogin({code: codeResponse.code, difm: difm}, navigate, login, setError); // <-- Ensure await here
                } else {
                    setError("There was an error retrieving the Google authentication code.");
                }
            } catch (error) {
                setError("There was an error with Google authentication.");
            } finally {
                setIsLoading(false); // Ensure loading is set to false after the login process is complete
            }
        },
        onError: (errorResponse) => {
            setError(`There was an error with Google authentication: ${errorResponse}`);
        }
    });

    // Determine the button text based on `buttonTextOption`
    let buttonText = "Logg inn med Google";
    if (buttonTextOption === "register") {
        buttonText = "Registrer med Google";
    }

    // Set background position based on buttonTextOption
    const backgroundPosition = buttonTextOption === "register" ? "133px 20px" : "125px 23px";
    // Set the width based on screen size
    const buttonWidth = isLargeScreen ? '40%' : isMediumScreen ? '68%' : '80%';
    // Responsive font size and logo size
    const fontSize = isLargeScreen ? '1rem' : isMediumScreen ? '0.9rem' : '0.8rem';
    const logoSize = isLargeScreen ? '24px' : isMediumScreen ? '20px' : '18px';
    const logoTop = isLargeScreen ? '13px' : isMediumScreen ? '16px' : '16px';

    return (
        <>
            {error && <Typography color="error">{error}</Typography>}
            <CustomButton
                variant="outlined"
                size="large"
                onClick={initiateGoogleLogin}
                sx={{
                    width: buttonWidth, position: 'relative', bgcolor: '#170F49', color: '#fff', fontSize: fontSize,
                    textTransform: 'none',
                    '&:hover': {
                        backgroundColor: '#170F49',
                        color: '#fff',
                        border: '2px solid ${color.darkBlue.baseBlue100}',
                    },
                }}
                style={{backgroundPosition: backgroundPosition}}
                disabled={isLoading}
            >
                <img
                    src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGcgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj48cGF0aCBkPSJNMTcuNiA5LjJsLS4xLTEuOEg5djMuNGg0LjhDMTMuNiAxMiAxMyAxMyAxMiAxMy42djIuMmgzYTguOCA4LjggMCAwIDAgMi42LTYuNnoiIGZpbGw9IiM0Mjg1RjQiIGZpbGwtcnVsZT0ibm9uemVybyIvPjxwYXRoIGQ9Ik05IDE4YzIuNCAwIDQuNS0uOCA2LTIuMmwtMy0yLjJhNS40IDUuNCAwIDAgMS04LTIuOUgxVjEzYTkgOSAwIDAgMCA4IDV6IiBmaWxsPSIjMzRBODUzIiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48cGF0aCBkPSJNNCAxMC43YTUuNCA1LjQgMCAwIDEgMC0zLjRWNUgxYTkgOSAwIDAgMCAwIDhsMy0yLjN6IiBmaWxsPSIjRkJCQzA1IiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48cGF0aCBkPSJNOSAzLjZjMS4zIDAgMi41LjQgMy40IDEuM0wxNSAyLjNBOSA5IDAgMCAwIDEgNWwzIDIuNGE1LjQgNS40IDAgMCAxIDUtMy43eiIgZmlsbD0iI0VBNDMzNSIgZmlsbC1ydWxlPSJub256ZXJvIi8+PHBhdGggZD0iTTAgMGgxOHYxOEgweiIvPjwvZz48L3N2Zz4="
                    alt="Google logo"
                    style={{
                        width: logoSize,
                        height: logoSize,
                        position: "absolute",
                        left: "20px",
                        top: logoTop,
                        display: isLoading ? 'none' : 'block'
                    }}
                />
                {isLoading ? <CircularProgress size={30} style={{color: '#FFFFFF'}}/> : buttonText}
            </CustomButton>
        </>
    );
};

export default GoogleAuthButton;
