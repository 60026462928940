import React from 'react';
import {Box, Card, CardContent, Typography} from '@mui/material';
import {CustomButton} from "../customComponents/CustomButton";
import {ArrowCircleRight} from "@mui/icons-material";

const ActionCard = ({title, description, onClick, videoSrc, manualWidth, buttonText, imageSrc, isMobile}) => (
    <Card
        sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            width: manualWidth ? manualWidth : {xs: '90%', sm: '60%', md: '50%'},
            padding: '1em',
            textAlign: 'center',
            transition: 'box-shadow 0.3s ease-in-out',
            borderRadius: '15px',
            boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
            borderStyle: 'solid',
            borderWidth: '0.2px',
            '&:hover': {
                boxShadow: '0px 8px 20px rgba(0, 0, 0, 0.15)',
            },
        }}
        onClick={onClick}
    >
        <CardContent sx={{display: 'flex', flexDirection: 'column', flexGrow: 1}}>
            <Typography
                variant="h4"
                component="div"
                sx={{mb: 2, fontWeight: 'bold', fontFamily: 'Fraunces'}}
            >
                {title}
            </Typography>
            <Typography
                variant="caption"
                sx={{
                    mb: 2,
                    fontWeight: 'bold',
                    letterSpacing: '0.04333em',
                    margin: '0 auto',
                }}
            >
                {description}
            </Typography>
            {videoSrc && (
                <Box
                    component="video"
                    sx={{
                        width: '100%',
                        maxHeight: {xs: '100px', sm: '100px', md: '200px', lg: '300px'},
                        height: '100%',
                        borderRadius: '5px',
                        objectFit: 'contain',
                        marginTop: '1em',
                    }}
                    src={videoSrc}
                    preload="metadata"
                    autoPlay
                    loop
                    muted
                />
            )}
            {imageSrc && (
                <Box
                    sx={{
                        width: '100%',
                        height: '150px',
                        borderRadius: '5px',
                        objectFit: 'contain',
                        marginTop: '1em',
                        alignContent: 'center',
                    }}>
                    <img width={isMobile ? "230px" : "320px"} alt="domeneleverandør logo" src={imageSrc}/>
                </Box>
            )}
            <CustomButton
                size="large"
                colors="sixth"
                sx={{
                    marginTop: '1em',
                    width: 'fit-content',
                    alignSelf: 'center',
                }}
            >
                {buttonText ? buttonText : title}
                <ArrowCircleRight sx={{paddingLeft: '0.2em'}}/>
            </CustomButton>
        </CardContent>
    </Card>
);

export default ActionCard;
