import axiosInstance from "../authentication/axiosInstance";

const checkCompleteOnboardingAndDifm = async () => {
    const flags = {
        isNew: localStorage.getItem('isNew') === 'true',
        difm: localStorage.getItem('difm') === 'true',
    };

    try {
        if (flags.isNew) {
            await axiosInstance.post('/complete-onboarding/');
            localStorage.setItem('isNew', 'false');
        }
        if (flags.difm) {
            await axiosInstance.post('/remove-difm-flag/');
            localStorage.setItem('difm', 'false');
        }
    } catch (error) {
        console.error('Error in checkCompleteOnboardingAndDifm:', error);
    }
};

export default checkCompleteOnboardingAndDifm;
