import axiosInstance from "../authentication/axiosInstance";
import {loadStripe} from "@stripe/stripe-js";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const generateDifmCheckoutLink = async () => {
    try {
        // Create a new checkout session via the API
        const response = await axiosInstance.post('/create-difm-checkout-session/');
        const {id} = response.data;

        // Initialize Stripe and redirect to checkout
        const stripe = await stripePromise;
        const {error} = await stripe.redirectToCheckout({sessionId: id});

        // Handle any errors during the redirect
        if (error) {
            console.error('Error redirecting to Stripe Checkout:', error);
        }
    } catch (error) {
        console.error('Error creating Stripe Checkout Session:', error);
    }
};

export default generateDifmCheckoutLink;
