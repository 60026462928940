import React, {createContext, useContext, useState} from "react";

const AuthContext = createContext();

export function useAuth() {
    return useContext(AuthContext);
}

const getInitialAuthState = () => {
    const isAuthenticated = !!localStorage.getItem('jwtToken');
    // Parse only if the item exists and is not the string "undefined"
    const isGoogleLoggedIn = localStorage.getItem('createdViaGoogle') && localStorage.getItem('createdViaGoogle') !== 'undefined'
        ? JSON.parse(localStorage.getItem('createdViaGoogle'))
        : false;
    const isGoogleConnected = localStorage.getItem('connectedGoogleAccount') && localStorage.getItem('connectedGoogleAccount') !== 'undefined'
        ? JSON.parse(localStorage.getItem('connectedGoogleAccount'))
        : false;
    return {
        isAuthenticated,
        isGoogleLoggedIn,
        isGoogleConnected,
    };
};


export function AuthProvider({children}) {
    const [authState, setAuthState] = useState(() => getInitialAuthState());

    const login = (
        jwtToken,
        createdViaGoogle,
        connectedGoogleAccount,
        jwtRefreshToken,
        first_name,
        last_name,
        google_access_token = null,
        google_refresh_token = null,
        google_email,
        email,
        isAdmin,
        brizy_user_id,
        workspace_id,
        isNew= 'false',
        difm = 'false',
    ) => {
        localStorage.setItem('jwtToken', jwtToken);
        localStorage.setItem('createdViaGoogle', createdViaGoogle);
        localStorage.setItem('connectedGoogleAccount', connectedGoogleAccount);
        localStorage.setItem('jwtRefreshToken', jwtRefreshToken);
        localStorage.setItem('google_email', google_email);
        localStorage.setItem('first_name', first_name);
        localStorage.setItem('last_name', last_name);
        localStorage.setItem('email', email);
        localStorage.setItem('brizy_user_id', brizy_user_id);
        localStorage.setItem('workspace_id', workspace_id);
        localStorage.setItem('isNew', isNew);
        localStorage.setItem('difm', difm);

        if (createdViaGoogle || connectedGoogleAccount) {
            if (google_access_token) {
                localStorage.setItem('google_access_token', google_access_token);
            }
            if (google_refresh_token) {
                localStorage.setItem('google_refresh_token', google_refresh_token);
            }
        }

        if (isAdmin) {
            localStorage.setItem('isAdmin', isAdmin);
        }

        setAuthState({
            isAuthenticated: true,
            isGoogleLoggedIn: createdViaGoogle,
            isGoogleConnected: connectedGoogleAccount,
        });
    };


    const logout = () => {
        localStorage.clear();
        localStorage.removeItem('connectedGoogleAccount');
        setAuthState({
            isAuthenticated: false,
            isGoogleLoggedIn: false,
            isGoogleConnected: false,
        });
    };

    const connectGoogleAccount = (isGoogleConnected, jwtToken, google_access_token, google_refresh_token, google_email) => {
        localStorage.setItem('jwtToken', jwtToken);
        localStorage.setItem('connectedGoogleAccount', isGoogleConnected);
        localStorage.setItem('google_access_token', google_access_token);
        localStorage.setItem('google_refresh_token', google_refresh_token);
        localStorage.setItem('google_email', google_email);

        setAuthState((prevState) => ({
            ...prevState,
            isAuthenticated: true,
            isGoogleLoggedIn: prevState.isGoogleLoggedIn || isGoogleConnected,
            isGoogleConnected: true,
        }));
    };

    return (
        <AuthContext.Provider value={{
            ...authState,
            login,
            logout,
            connectGoogleAccount,
        }}>
            {children}
        </AuthContext.Provider>
    );
}
