export const AnimationLinks = () => {
    const malerNettsideVideoUrl = "https://growebmp4container-euczdjadazhtdkhf.z02.azurefd.net/growebmp4container/maler-nettside.webm";
    const aiByggerVideoUrl = "https://growebmp4container-euczdjadazhtdkhf.z02.azurefd.net/growebmp4container/ai-builder-animation.webm";
    const guiderVideoUrl = "https://growebmp4container-euczdjadazhtdkhf.z02.azurefd.net/growebmp4container/guides-animation.webm";
    const logoUrl = "https://growebmp4container-euczdjadazhtdkhf.z02.azurefd.net/growebmp4container/logo-final.webp"
    const domainBuyUrl = "https://growebmp4container-euczdjadazhtdkhf.z02.azurefd.net/growebmp4container/domain-buy.webm"
    const domainBuyUrl2 = "https://growebmp4container-euczdjadazhtdkhf.z02.azurefd.net/growebmp4container/domain-buy2.webm"
    const logoSmallUrl = "https://growebmp4container-euczdjadazhtdkhf.z02.azurefd.net/growebmp4container/logo-small.png"
    const goDaddyLogo = "https://growebmp4container-euczdjadazhtdkhf.z02.azurefd.net/growebmp4container/goDaddyLogo.png"

    return {
        malerAnimation: malerNettsideVideoUrl,
        aiByggerAnimation: aiByggerVideoUrl,
        guiderAnimation: guiderVideoUrl,
        logoUrl,
        domainBuyUrl,
        domainBuyUrl2,
        logoSmallUrl,
        goDaddyLogo
    };
}
